import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "ResultView"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1
};
import { onMounted, ref } from "vue";
import CacheData from "@/utils/Cache/CacheData";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import HeaderNavBar from '@/components/Header/HeaderNavBar';
import LangCom from "@/utils/Lang/LangViews";
export default {
  __name: 'Result',
  setup(__props) {
    const LangLib = new LangCom('Common', 'Result');
    const GetLangTemplate = field => LangLib.GetTemplateLang(field);
    const GetLangSetup = field => LangLib.GetSetupLang(field);
    let Status = 100; //[解析结果状态] 100.成功 1.解析数据异常
    let resultArrOjb = ref([]);
    onMounted(() => {
      let cacheData = CacheData.GetData('ResultPage');
      console.log('[onMounted]ResultPage', cacheData);
      if (!Array.isArray(cacheData)) {
        VantMsgLib.alert(GetLangSetup('onMounted.tips.arr'));
        Status = 1;
        return;
      }
      resultArrOjb.value = cacheData;
      VantMsgLib.notify(2, GetLangSetup('onMounted.tips.notify'));
    });
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_cell = _resolveComponent("van-cell");
      const _component_van_cell_group = _resolveComponent("van-cell-group");
      const _component_van_back_top = _resolveComponent("van-back-top");
      const _component_van_empty = _resolveComponent("van-empty");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(HeaderNavBar), {
        title: GetLangTemplate('HeaderNavBar')
      }, null, 8, ["title"]), _unref(Status) === 100 ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_van_cell_group, {
        inset: ""
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(resultArrOjb), item => {
          return _openBlock(), _createBlock(_component_van_cell, {
            key: item.object,
            title: item.object,
            value: item.id || -1,
            label: item.msg
          }, _createSlots({
            _: 2
          }, [item.state != 100 ? {
            name: "right-icon",
            fn: _withCtx(() => [_createVNode(_component_van_icon, {
              name: "cross",
              color: "red"
            })]),
            key: "0"
          } : {
            name: "right-icon",
            fn: _withCtx(() => [_createVNode(_component_van_icon, {
              name: "success",
              color: "green"
            })]),
            key: "1"
          }]), 1032, ["title", "value", "label"]);
        }), 128))]),
        _: 1
      }), _createVNode(_component_van_back_top)])) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_van_empty, {
        image: "error",
        description: "Execution Exception"
      })]))]);
    };
  }
};